import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import nookies from 'nookies';
import { withAuth0 } from '@auth0/auth0-react';
import B2BGiftVoucher from '../B2BGiftVoucher';
import ApplyNowPopup from './ApplyNowPopup';
import Banner from './Banner';
import B2BBanner from './B2BBanner';
import HowItWorks from './HowItWorks';
import CorporateWorks from './CorporateWorks';
import CorporateGifting from './CorporateGifting';
import NeedHelp from './NeedHelp';
import FAQs from './FAQs';
import B2BFAQs from './B2BFAQs';
import CorporateVoucher from './CorporateVoucher';
import B2BAppreciation from './B2BAppreciation';
import { useFeatureFlag } from '../FeatureFlagProvider';
import Loader from '../Theme/Loader';
import PageFooter from '../Theme/PageFooter';
import { CustomiseVoucherContext } from '../Context/Context';
import {
  CATEGORY,
  GTM_ENV_VAR as GEV,
  PAGE_TYPES,
  SCREEN_NAME,
} from '../../lib/eventTrackerFunction';
import { EventTrackingContext } from '../EventTrackingProvider/EventTrackingProvider';
import { AMP_EVENT_NAME } from '../../lib/amplitude';
import B2BStores from './B2BStores';
import TableComparison from './TableComparison';
import { headers, rows } from './utils';
import SPLIT_FEATURES from '../../SplitContext/features';
import { SplitContext } from '../../SplitContext/splitConfiguration';
import useBusinessEntityData from '../../hooks/useBusinessEntityData';
import { useAppContext } from '../../lib/contextProviders/contexts/appContext';
import { useUserContext } from '../../lib/contextProviders/contexts/userContext';
import { isIosDevice } from '../../lib/utils';

const customiseRoute = '/customise-voucher/';
const EGIFTING_HOME = 'egifting | home';

export const sendToCustomise = (router, voucher) => {
  nookies.set(
    null,
    'selectedVoucher',
    JSON.stringify({
      category: voucher?.catergory || 'all',
      image: voucher?.image || '',
      png: voucher?.png || '',
      message: voucher?.message || '',
    }),
    null
  );

  const windowVar = typeof window !== 'undefined' && window;
  windowVar.location.href = customiseRoute;
};

export const sendToB2BHomePage = (router) => {
  const windowVar = typeof window !== 'undefined' && window;
  router?.push('/');
  windowVar.location.href = '/';
};

export const applyNowURL =
  typeof window !== 'undefined' &&
  !window.location.origin.includes('preprod') &&
  !window.location.origin.includes('localhost')
    ? 'https://forms.gle/rP2RHeuPVfTCshaP9'
    : 'https://forms.gle/yWm7cUgAUzg4JY5C8';

export const handleB2BEvent = (
  isApply,
  isLoggedIn,
  voucher,
  isEntityDataAvailable,
  setSelectedVoucher,
  router,
  setApplyModelOpen
) => {
  const isPopupType = typeof isApply === 'boolean' ? isApply : false;
  if (!isLoggedIn) {
    localStorage.setItem('isB2B', true);
    localStorage.setItem('isPopupType', isPopupType);
    localStorage.setItem('voucherData', JSON.stringify(voucher));
    sendToCustomise(router, voucher);
  } else if (voucher !== null && isEntityDataAvailable && !isPopupType) {
    setSelectedVoucher({
      category: voucher.catergory,
      image: voucher.image,
      png: voucher.png,
      message: voucher.message,
    });
    sendToCustomise(router, voucher);
  } else if (isEntityDataAvailable && !isPopupType) {
    sendToCustomise(router, voucher);
  } else if (isEntityDataAvailable && isPopupType) {
    sendToB2BHomePage(router);
  } else if (isPopupType) {
    window.open(applyNowURL, isIosDevice() ? '_self' : '_blank');
  } else if (!isEntityDataAvailable && !isPopupType) {
    setApplyModelOpen(true);
  }
  return null;
};

function LandingPage(props) {
  const { isReady } = useFeatureFlag();
  const [applyModelOpen, setApplyModelOpen] = useState(false);
  const router = useRouter();
  const { track, splits } = props;
  const { setSelectedVoucher, voucherMetaData, banerImage } = useContext(CustomiseVoucherContext);
  const trackEvents = {
    [GEV.PAGE_TYPE]: PAGE_TYPES.HOME,
    [GEV.SCREEN_NAME]: SCREEN_NAME.HOME,
    [GEV.EVENT_CAT]: CATEGORY.VOUCHER,
  };
  const { isB2BSite, entityData, isEntityDataReady } = useBusinessEntityData();
  const {
    appState: { siteType },
  } = useAppContext();
  const {
    userState: { isLoggedIn },
  } = useUserContext();

  const onSendNow = () => {
    sendToCustomise(router);
    track({
      [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.SEND_NOW_CLICK,
      [GEV.EVENT_CAT]: 'Egifting',
      [GEV.MEMBER_TYPE]: siteType,
      [GEV.AMPLITUDE_VERTICAL]: AMP_EVENT_NAME.VERTICAL,
      [GEV.AMPLITUDE_CTA_LOCATION]: EGIFTING_HOME,
      [GEV.AMPLITUDE_CTA_SUB_LOCATION]: 'widget | send now',
    });
  };

  const isEntityDataAvailable = !!entityData?.id;

  const handleB2BAction = (isApply = false, voucher = null) => {
    const hasVoucher = voucher
      ? `widget | card design tile | ${voucher.name}`
      : 'widget | order now';
    if (!isApply) {
      track({
        [GEV.AMPLITUDE_EVENT_NAME]: voucher
          ? AMP_EVENT_NAME.CARD_DESIGN_TILE_CLICK
          : AMP_EVENT_NAME.SEND_NOW_CLICK,
        [GEV.MEMBER_TYPE]: siteType,
        [GEV.AMPLITUDE_VERTICAL]: AMP_EVENT_NAME.VERTICAL,
        [GEV.AMPLITUDE_CTA_LOCATION]: EGIFTING_HOME,
        [GEV.AMPLITUDE_CTA_SUB_LOCATION]: hasVoucher,
        ...(isB2BSite && {
          [GEV.AMPLITUDE_GIFTING_CUSTOMER_LOGIN_STATUS]: isLoggedIn ? 'logged in' : 'guest',
        }),
      });
    }

    if (!isB2BSite) {
      sendToCustomise(router, voucher);
    }

    return handleB2BEvent(
      isApply,
      isLoggedIn,
      voucher,
      isEntityDataAvailable,
      setSelectedVoucher,
      router,
      setApplyModelOpen
    );
  };

  useEffect(() => {
    const localStorageB2B = localStorage.getItem('isB2B');
    const localStoragePopupType = localStorage.getItem('isPopupType');
    const localStorageVoucherData = localStorage.getItem('voucherData');
    if (isLoggedIn && isB2BSite && localStorageB2B === 'true' && isEntityDataReady) {
      handleB2BAction(
        localStoragePopupType === 'true',
        localStorageVoucherData === 'null' ? null : JSON.parse(localStorageVoucherData)
      );
      localStorage.removeItem('isB2B');
      localStorage.removeItem('isPopupType');
      localStorage.removeItem('voucherData');
    }
  }, [isLoggedIn, isB2BSite, isEntityDataReady]);

  useEffect(() => {
    track({
      [GEV.EVENT_ACTION]: 'landed',
      [GEV.EVENT_LABEL]: 'gifting_landing_page',
      ...trackEvents,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // await till flag are loaded
  if (!isReady) {
    return <Loader />;
  }
  const isComparisonEnable = splits?.[SPLIT_FEATURES.COMPARISON_TABLE]?.treatment === 'on';
  return (
    <>
      {isB2BSite ? (
        <>
          <B2BBanner
            isEntityDataAvailable={isEntityDataAvailable}
            handleB2BAction={handleB2BAction}
            track={track}
          />
          <CorporateWorks />
          {isComparisonEnable && <TableComparison headers={headers} rows={rows} />}
          <B2BFAQs />
          <B2BGiftVoucher data={voucherMetaData} handleB2BAction={handleB2BAction} track={track} />
          <B2BStores />
          {!isEntityDataAvailable ? <CorporateGifting handleB2BAction={handleB2BAction} /> : null}
          <NeedHelp />
          <B2BAppreciation handleB2BAction={handleB2BAction} />
          {applyModelOpen && (
            <ApplyNowPopup
              open={applyModelOpen}
              applyNowURL={applyNowURL}
              closeModal={() => setApplyModelOpen(false)}
              track={track}
            />
          )}
        </>
      ) : (
        <>
          <Banner bannerImg={banerImage} onSendNow={onSendNow} />
          <HowItWorks />
          <B2BGiftVoucher
            isB2c
            data={voucherMetaData}
            handleB2BAction={handleB2BAction}
            track={track}
          />
          <FAQs />
          <B2BStores />
          <NeedHelp isB2c />
          <CorporateVoucher router={router} />
        </>
      )}
      <PageFooter />
    </>
  );
}

const LandingPageWrapper = (props) => {
  const splitConfig = useContext(SplitContext);
  const { splits } = splitConfig;

  return (
    <EventTrackingContext.Consumer>
      {({ track }) => <LandingPage {...props} splits={splits} track={track} />}
    </EventTrackingContext.Consumer>
  );
};

export default withAuth0(LandingPageWrapper);
