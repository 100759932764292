/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import styled from 'styled-components';
import Grid from '@ntuctech/devex-tangram/Grid';
import { Link, Typography } from '@ntuctech/devex-tangram';
import Facebook from '../../icons/svg/Facebook.svg';
import Youtube from '../../icons/svg/Youtube.svg';
import Instagram from '../../icons/svg/Instagram.svg';
import barcode from '../../icons/svg/barcode.svg';
import mastercard from '../../icons/svg/master-card-light.svg';
import amex from '../../icons/svg/amex-light.svg';
import visa from '../../icons/svg/visa.svg';
import CancelModal from '../../CustomiseVoucher/component/CancelModal';
import { SCREEN_SIZE } from '../../../lib/mediaQuery';
import { B2B, B2C } from '../../../constants';
import useBusinessEntityData from '../../../hooks/useBusinessEntityData';
import { isIosDevice } from '../../../lib/utils';

const StyledSecurePaymentMethod = styled(Typography)`
  color: #696969;
  font-weight: 900;
  font-size: 1rem;
`;

const StyledFairPriceSecure = styled.div`
  color: #1454b8;
  margin-bottom: 1.5rem !important;
`;

const FooterBox = styled.div`
  .no-spacing {
    margin: 0;
    padding: 0;
  }
  .margin-right-16 {
    margin-right: 1rem;
  }
  .float-right {
    ${SCREEN_SIZE.From.Desktop} {
      margin-left: auto;
      margin-right: 0;
    }
  }
  .card-styles {
    width: 2rem;
    height: 2rem;
    margin: 0.5rem 0.5rem 0.5rem 0;
  }
  background: #fff;
  padding: 25px 0;
  ul,
  h5,
  p {
    margin: 0;
  }
  li:nth-child(1) {
    margin-bottom: 15px;
  }
  a {
    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1454b8;
    text-decoration: none;
  }
  h5 {
    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #696969;
  }
  @media screen and (max-width: 767px) {
    ul {
      display: none;
    }
  }
`;
const RmvInnerPad = styled.div`
  @media screen and (min-width: 768px) {
    > div:nth-child(1) {
      padding: 0 0;
    }
    > div:nth-child(1) > div:nth-child(1) {
      padding-left: 0;
    }
  }
  a {
    cursor: pointer;
  }
`;

const SocialMediaIcons = styled.div`
  padding-top: 10px;
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  a:nth-last-child(1) img {
    margin-right: 0px;
  }
`;
const FollowUsSection = styled.div`
  margin: 0 0 0 auto;
`;
const DownloadSection = styled.div`
  margin: 0 0 0 auto;
  a:nth-last-child(1) img {
    margin-right: 0px;
  }
  @media screen and (max-width: 1100px) {
    width: auto;
  }
  @media screen and (max-width: 767px) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  img {
    width: 7.125rem;
    height: 7.125rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    padding: 0;
  }
`;
const BottomBorderLine = styled.div`
  background: #eaeaea;
  height: 1px;
  margin: 10px 0px;
`;
const BelowFooter = styled.div`
  padding-top: 10px;
  li {
    margin-bottom: 0 !important;
    float: left;
    margin-right: 15px;
  }
  span {
    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #696969;
  }
  a {
    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #999999;
  }
  @media screen and (max-width: 767px) {
    ul {
      display: block;
    }
    li {
      float: none;
      margin-right: 0px;
      text-align: center;
    }
    li:nth-child(2) {
      display: none;
    }
  }
`;

function PageFooter() {
  const { isB2BSite } = useBusinessEntityData();
  const [isCancelPopupOpen, setisCancelPopupOpen] = useState(false);

  const handleNavigationClick = () => {
    if (
      ['/customise-voucher/', '/cart/', '/my-orders/', '/my-orders/details/'].includes(
        window.location.pathname
      )
    ) {
      setisCancelPopupOpen({ isCancelPopupOpen: true });
    } else {
      window.location.href = `/?type=${isB2BSite ? B2C : B2B}`;
    }
  };

  return (
    <>
      <FooterBox>
        <Grid container>
          <Grid item s={10}>
            <RmvInnerPad>
              <Grid container className="no-spacing">
                <ul className="margin-right-16">
                  <li>
                    <a
                      data-testid="vouchers-link"
                      aria-label={
                        isB2BSite ? 'Personal E-Gift Vouchers' : 'E-Gift Vouchers for Business'
                      }
                      onClick={() => handleNavigationClick()}
                    >
                      {isB2BSite ? 'Personal E-Gift Vouchers' : 'E-Gift Vouchers for Business'}
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="Help"
                      href="https://help.fairprice.com.sg/hc/en-us/sections/9514637359385-FairPrice-E-Gift-Vouchers"
                    >
                      Help
                    </a>
                  </li>
                </ul>

                <Grid item s={5} className="float-right no-spacing">
                  <Grid className="no-spacing">
                    <StyledSecurePaymentMethod>SECURE PAYMENT METHODS</StyledSecurePaymentMethod>
                    <img alt="mastercard" src={mastercard.src} className="card-styles" />
                    <img alt="mastercard" src={visa.src} className="card-styles" />
                    <img alt="mastercard" src={amex.src} className="card-styles" />
                    <StyledFairPriceSecure>
                      <Link size="l" href="https://www.fairpricegroup.com.sg/">
                        About FairPrice Secure Checkout
                      </Link>
                    </StyledFairPriceSecure>
                    <FollowUsSection>
                      <h5>FOLLOW US ON</h5>
                      <SocialMediaIcons>
                        <a aria-label="Facebook" href="https://www.facebook.com/thatsmyfairprice">
                          <img alt="" src={Facebook.src} />
                        </a>
                        <a aria-label="Youtube" href="https://www.youtube.com/user/NTUCFairPriceSG">
                          <img alt="" src={Youtube.src} />
                        </a>
                        <a aria-label="Instagram" href="https://www.instagram.com/fairpricesg/">
                          <img alt="" src={Instagram.src} />
                        </a>
                      </SocialMediaIcons>
                    </FollowUsSection>
                  </Grid>
                </Grid>
              </Grid>
            </RmvInnerPad>
          </Grid>
          <Grid item s={2}>
            <DownloadSection>
              <h5>DOWNLOAD OUR APP</h5>
              <img src={barcode.src} alt="download" />
            </DownloadSection>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item s={12}>
            <BottomBorderLine />
            <BelowFooter>
              <ul>
                <li>
                  <a href="https://www.fairprice.com.sg/">Copyright © 2022 FairPrice Group.</a>
                </li>
                <li>
                  <a href="https://www.fairprice.com.sg/">|</a>
                </li>
                <li>
                  <a href="https://help.fairprice.com.sg/hc/en-us/articles/4464676591129-Privacy-Notice-with-effect-from-2-April-2022-">
                    Privacy policy
                  </a>
                </li>
                <li>
                  <a href="https://help.fairprice.com.sg/hc/en-us/articles/9729943297689-Terms-Conditions-of-Sale-of-E-Gift-Voucher-">
                    Terms & conditions
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ntucenterprise.sg/contactus/vulnerability-disclosure-programme/"
                    target={isIosDevice() ? '_self' : '_blank'}
                    rel="noreferrer"
                  >
                    Report vulnerability
                  </a>
                </li>
              </ul>
            </BelowFooter>
          </Grid>
        </Grid>
      </FooterBox>
      <CancelModal
        open={isCancelPopupOpen}
        onClose={() => setisCancelPopupOpen(false)}
        isSwitching
        onSwitchClick={() => {
          setisCancelPopupOpen(false);
          window.location.href = `/?type=${isB2BSite ? B2C : B2B}`;
        }}
      />
    </>
  );
}

export default PageFooter;
